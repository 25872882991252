import React from 'react';
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';

function LayoutContainer(props) {
  return (
    <div>
      <Header />
        <div
          className="section"
        >
          {props.children}
        </div>
      {/* <Footer /> */}
    </div>
  );
}

export default LayoutContainer;
