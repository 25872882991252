import React, {useState, useEffect, useRef} from 'react';

import download from '../images/download.svg';
import Api from '../services/Api';
import Loading from './Loading';

const PhotoItem = ({item}) => {
  const [loading, setLoading] = useState(false);

  const downloadImage = (image) => {
    setLoading(true);
    return Api.getImage(image.original, image.content_type)
      .then(r => r.blob())
      .then((blob) => {
        const newBlob = new Blob([blob], {type: image.content_type});

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        } 
      
        // For other browsers: 
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement('a');
        link.href = data;
        link.download= image.file_name;
        link.click();
        setTimeout(function(){
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          setLoading(false);
        }, 100);
      });
  };

  return (
    <div className="photo" key={item.uuid}>
      {
        item.content_type === 'video/mp4' ?
        <div>
          <h3>Download the video to play it</h3>
        </div>
        :
        <img
          src={item.original}
          styles={{
            backgroundImage: `url(${item.original})`
          }}
          alt="item"
          className="item-img"
        />
      }
      <div className="menu">
        <div>{item.file_name}</div>
        <div>
          {
            !loading ?
              <img
                onClick={() => downloadImage(item)}
                src={download}
                alt="download"
                className="download-img"
              />
            : 
              <div style={{
                position: 'relative',
                width: '25px',
                height: '25px'
              }}>
                <Loading />
              </div>
          }
        </div>
      </div>
    </div>
  );
};

export default PhotoItem;