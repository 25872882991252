import React, {useState, useEffect, useRef} from 'react';
import Api from '../services/Api';
import Sidebar from "react-sidebar";
import Preview from './Preview';
import {getResponsiveSize} from '../helpers/responsive';
import backgroundImage from '../images/background-photos.jpg';

const Details = (props) => {
  const size = getResponsiveSize(window.innerWidth);

  const [sidebarOpen, setSidebar] = useState(false);

  const [gallery, setGallery] = useState({
    photos: [],
  });
  const {folio} = props.match.params;
  useEffect(() => {
    const fetchGallery = async () => {
      const [error, response] = await Api.find(folio);
      if (error) {
        props.history.push('/not-found');
      }
      if (response.photos.length < 1) {
        props.history.push('/photos/not-yet');
      }
      setGallery(response);
    }
    fetchGallery();
  }, [folio]);

  const getImageUrl = ({id_reservacion, id}, {uuid, file_name}) => `${gallery.base_url}${id_reservacion ? id_reservacion : id}/${uuid}-${file_name}`;
  
  const setItemProps = photo => ({
    original: getImageUrl(gallery, photo),
    thumbnail: getImageUrl(gallery, photo),
    originalHeight: '500px',
    content_type: photo.content_type,
    file_name: photo.file_name,
    uuid: photo.uuid
  });

  const downloadZip = () => {
    const {base_url, id_reservacion, uuid, file_name, content_type, id} = gallery;
    const zip = `${base_url}${id_reservacion ? id_reservacion : id}/${uuid}-${file_name}`;
    
    return Api.getImage(zip, content_type)
      .then(r => r.blob())
      .then((blob) => {
        const newBlob = new Blob([blob], {type: content_type});

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        } 
      
        // For other browsers: 
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement('a');
        link.href = data;
        link.download= file_name;
        link.click();
        setTimeout(function(){
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      });
  };

  const items = gallery.photos.map(setItemProps);

  const previewComponent = 
    <Preview 
      items={items}
      downloadAll={downloadZip}
      onClose={() => {setSidebar(false)}}
      size={size}
    />;

  if (size === 'SMARTPHONE' || size === 'TABLET') {
    return previewComponent;
  }

  return (
    <Sidebar
      sidebar={previewComponent}
      open={sidebarOpen}
      onSetOpen={setSidebar}
      styles={{ sidebar: {
          width: '80%'
        },
        root: {
          backgroundImage: `url(/assets/background-photos.jpg)`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }
      }}
      pullRight={true}
    >
      <div className="container-max-width">
        <div className="photos-container">
          <div className="floating-download-container">
            <div className="span-container">
              <span className="icon-camera" />
            </div>
            <h2>Ready when you are</h2>
            <ul>
              <li
                onClick={() => setSidebar(true)}
              >
                <div>
                  {
                    `${items.length} files`
                  }
                </div>
                <div>
                  <div className="preview-button">
                    Preview
                  </div>
                </div>
              </li>
            </ul>
            <div className="download-button-container">
              <button
                onClick={downloadZip}
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  );
}

export default Details;
