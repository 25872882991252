import React from 'react';

const Loading = props => (
  <div className="loading-container"
  >
    <div className="pulse" />
  </div>
);

export default Loading;
