import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Layout from './pages/LayoutContainer';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Details from './pages/Details';
import NotYet from './pages/NotYetPage';

function App() {
  return (
    <div className="App">
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/not-found" component={NotFound} />
            <Route exact path="/photos/find/:folio" component={Details} />
            <Route exact path="/photos/not-yet" component={NotYet} />
          </Switch>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
