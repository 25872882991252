import React, {useState} from 'react';
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
} from 'formik';
import _ from 'lodash';
import Api from '../services/Api';
import {errorCodes} from '../helpers/http';
import {HOME} from '../language/index';

const Home = (props) => {
  const [apiError, setError] = useState(null);

  const getInitialValues = () => {
    return {
      folio: '',
      completeName: '',
    };
  }

  const validate = (values) => {
    const errors = {};
    const  {folio} = values;

    if (_.isEmpty(folio)) {
      errors.folio = 'Folio is required';
    }

    return errors;
  }

  const isFormValid = (errors, touched) => _.isEmpty(errors) && !_.isEmpty(touched);

  const onSubmit = async (values) => {
    const [error, response] = await Api.find(values.folio);
    if (error) {
      setError(errorCodes[error.statusCode]);
      return;
    }
    if (response.photos.length > 0) {
      props.history.push(`/photos/find/${response.folio}`);
    } else  {
      props.history.push(`/photos/not-yet`);

    }
  }

  return (
    <div className="container-max-width checkout">
      <Formik
        enableReinitialize={true}
        initialValues={getInitialValues()}
        validate={validate}
        onSubmit={onSubmit}
      >
        {
          ({
            errors,
            touched,
          }) => (
            <Form>
              <div className="checkout__card home_page">
                <div>
                  <div className="checkout__card__header">
                    <div className="checkout__card__title">
                      <span className="icon-camera" />
                        {HOME.title}
                    </div>
                    <div className="checkout__card__text">
                      {HOME.subtitle}
                    </div>
                    <div className="checkout__label-input">
                      <span>{apiError}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="checkout__row">
                    <div className="checkout__label-input">
                      <label>{HOME.folioInput}</label>
                      <Field
                        type="text"
                        name="folio"
                        placeholder={HOME.folio_placeholder}
                      />
                      <ErrorMessage
                        component="span"
                        name="folio"
                      />
                    </div>
                  </div>
                </div>
                <div className="checkout__buttons">
                  <button
                    type="submit"
                    className={`checkout__buttons__confirm ${isFormValid(errors, touched) ? 'form-valid' : ''}`}
                  >
                    {HOME.search}
                  </button>
                </div>
              </div>
            </Form>
          )
        }
      </Formik>
    </div>
  );
}

export default Home;
