const GENERAL_ERROR = 'Oh no, an unexpected error occured, try again!';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const EXTEND_DATE_FORMAT = 'dddd, MMMM Do YYYY';
export const BASIC_DATE_FORMAT = 'MMMM Do YYYY';

export const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo', 'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
];

export const OPERATIONS = {
  1: 'Directos',
  3: 'Todos Santos',
  4: 'La Paz',
  5: 'Sunset',
  6: 'City Tours'
};

export const STATUSES = [
  'Reservada',
  'No show',
  'Cancelada',
]

export default {
  GENERAL_ERROR,
  DATE_FORMAT,
  EXTEND_DATE_FORMAT,
  MONTHS,
  BASIC_DATE_FORMAT,
  OPERATIONS,
  STATUSES,
};
