import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import images from '../../images';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <header>
        <nav className="">
          <div className="navbar-menu down-brush">
            <div>
              
            </div>
            <div className="navbar-menu--brush">
              <div>
                <a href="https://www.esperanzastours.com/">
                  <img src={images.headerLogo} alt="Logo Esperanzas Tours" />
                </a>
              </div>
            </div>
            <div>
              <div className="navbar-menu__social-media">
                <a
                  href="https://www.youtube.com/channel/UCS9e-21JZA53zCkJ7CB-KAg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>
                    <span className="icon-youtube" />
                  </div>
                </a>
                <a
                  href="https://www.facebook.com/esperanzastoursoficial/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>
                    <span className="icon-facebook" />
                  </div>
                </a>
                <a
                  href="https://www.instagram.com/esperanzastoursoficial/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>
                    <span className="icon-instagram" />
                  </div>
                </a>
              </div>
              
            </div>
          </div>
          <div className="responsive-navbar-menu down-brush">
            <div className="responsive-navbar-menu__left">
             
              <div />
            </div>
            <div className="responsive-navbar-menu__logo">
              <img src={images.headerLogo} alt="Logo Esperanzas Tours" />
            </div>
            <div className="responsive-navbar-menu__right">
              <a
                href="https://www.facebook.com/esperanzastoursoficial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div>
                  <span className="icon-facebook" />
                </div>
              </a>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default Header;
