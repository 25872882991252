import { get, getFile } from '../helpers/http';

class Api {
  async find(folio, name) {  
    try {
      const response = await get(`/api/gallery/find?folio=${folio}`);
      return [null, response];
    } catch (err) {
      return [err, null];
    }
  }

  getImage(url, type) {
    return getFile(url);
  }
}

export default new Api();
