import React from 'react';
import PhotoItem from './PhotoItem';

const Preview = ({items, downloadAll, onClose, size}) => {
  const divideArray = (items) => {
    
    const half = Math.ceil(items.length / 2);    

    const firstHalf = items.slice(0, half);
    const secondHalf = items.slice(-half);
    return [firstHalf, secondHalf];
  };
  
  const [
    firstItems,
    lastItems,
  ] = divideArray(items);

  const isResponsive = size === 'SMARTPHONE' || size === 'TABLET';

  return (
    <div className="preview-container">
      <div className="header">
        <div>
          <div className="left-header">
            {
              !isResponsive ?
                <button
                  className="close-preview"
                  onClick={onClose}
                >
                  &#88;
                </button>
              : undefined
            }
          </div>
          <div>
            <h1>Your photos</h1>
            <label>{`${items.length} files`}</label>
          </div>
        </div>
        <div>
          <button
            className="download-zip-button"
            onClick={downloadAll}
          >
            Download All
          </button>
        </div>
      </div>
      {
        isResponsive ?
          <div>
            <div className="photos">
              {
                items.map(item =>
                  <PhotoItem
                    key={item.uuid}
                    item={item}
                  />
                )
              }
            </div>
          </div>
        :
        <div className="container-photos">
          <div className="photos">
            {
              firstItems.map(item =>
                <PhotoItem
                  key={item.uuid}
                  item={item}
                />
              )
            }
          </div>
          <div className="photos">
            {
              lastItems.map(item =>
                <PhotoItem
                  key={item.uuid}
                  item={item}
                />
              )
            }
          </div>
        </div>
      }
    </div>
  )
};

export default Preview;