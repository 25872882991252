import React from 'react';
import {Link} from 'react-router-dom';
import {NOTYET} from '../language/index';

const Details = (props) => {
  return (
    <div className="container-max-width">
      <div className="checkout__confirmation">
        <div>
          <h1>{NOTYET.h1}</h1>
          <p>{NOTYET.p}</p>
          <div className="checkout__buttons">
            <Link
              className="checkout__buttons__confirm form-valid"
              to="/"
            >
              {NOTYET.link}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Details;
