export const DESKTOP = 'DESKTOP';
export const SMALL_DESKTOP = 'SMALL_DESKTOP';
export const TABLET = 'TABLET';
export const SMARTPHONE = 'SMARTPHONE';


export const getResponsiveSize = (width) => {
  if (width > 900) {
    return DESKTOP;
  } else if (width > 750 && width < 900) {
    return SMALL_DESKTOP;
  } else if (width > 550 && width < 750) {
    return TABLET;
  }

  return SMARTPHONE;
}

export const CATEGORY_IMAGES_MAP = {
  DESKTOP: 4,
  SMALL_DESKTOP: 3,
  TABLET: 2,
  SMARTPHONE: 1,
};

export const TOUR_IMAGES_MAP = {
  DESKTOP: 2,
  SMALL_DESKTOP: 2,
  TABLET: 1,
  SMARTPHONE: 0,
};